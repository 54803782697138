import { useFavicon } from "@vueuse/core";
import { instance } from "./axios";
const icon = useFavicon();

async function setupConfig() {
  try {
    let location = window.location.hostname;
    let brand = location.split("-")[0];
    if (brand == "linecrm" || brand == "5t8z5pcj") brand = "d-dots";
    document.title = brand.charAt(0).toUpperCase() + brand.slice(1) + " - CRM";
    if (process.env.VUE_APP_MODE == "dev") {
      icon.value = "/images/dev.ico";
      document.title = "D-CRM";
    } else icon.value = `/images/${brand}.ico`;
    const response = await instance.get("/api/v1/setting/getline");

    return response.detail; // Assuming the response is JSON data
  } catch (error) {
    window.alert(`errr: ${error}`);
    return null;
  }
}
export { setupConfig };
