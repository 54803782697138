<template>
  <div id="app">
    <div id="app-container" class="container-lg p-0">
      <Header v-if="isLoadingTheme && !isShowHeader" @updateView="updateView" />
      <div class="container-router">
        <div
          :class="
            isShowing
              ? 'background-telephone-color'
              : background
              ? 'background-color'
              : 'background-image'
          "
        ></div>

        <template v-if="readyToUse">
          <router-view :key="componentKey"> </router-view>
          <div class="nav-footer" v-if="showFooter">
            <div
              @click="$router.push('/profile')"
              :class="[
                activePath == '/profile' ? 'active' : '',
                'nav-footer-items'
              ]"
            >
              <font-awesome-icon :icon="['fas', 'home']" />
            </div>
            <div
              @click="$router.push('/privilege')"
              :class="[activePath == '/privilege' ? 'active' : '']"
              class="position-relative nav-footer-items"
            >
              <v-badge
                color="error"
                pill
                class="badge-redeem"
                v-if="$store.state.shortProfile.total_coupon_can_use > 0"
                :content="$store.state.shortProfile.total_coupon_can_use"
              >
                <!-- <span>{{}}</span> -->
              </v-badge>
              <font-awesome-icon :icon="['fas', 'ticket-alt']" />
            </div>
            <div
              :class="[activePath == '/reward' ? 'active' : '']"
              class="nav-footer-items"
              @click="$router.push('/reward')"
            >
              <font-awesome-icon :icon="['fas', 'gift']" />
            </div>
            <div
              :class="[activePath == '/manage' ? 'active' : '']"
              class="nav-footer-items"
              @click="$router.push('/manage')"
            >
              <font-awesome-icon :icon="['fas', 'user']" />
            </div>
          </div>
        </template>
        <template v-else>
          <Redirect />
        </template>
      </div>

      <Modal />
    </div>
  </div>
</template>

<script>
import Loading from "@/components/other/Loading";
import Header from "@/views/layout/Header";
import Redirect from "@/views/redirect";
import Modal from "@/components/modal";

export default {
  components: {
    Header,
    Modal,
    Loading,
    Redirect
  },

  data() {
    return {
      setData: "",
      theme: {},
      isLoadingTheme: false,
      isLoading: false,
      background: false,
      MenuList: [],
      activePath: "",
      lineProfile: null,

      setProfile: false,
      componentKey: 1
    };
  },

  watch: {
    "$route.path": {
      handler: function (to, from, val) {
        this.activePath = to;
      },
      deep: true,
      immediate: true
    }
  },
  async beforeCreate() {},
  async created() {
    this.getTheme();
    try {
      // this.$liff.ready
      //   .then(async () => {
      if (!this.$liff.isLoggedIn()) {
        return this.$liff.login({
          redirectUri: `${window.location.origin}?redirect=${
            window.location.pathname + window.location.search
          }`
        });
      }
      // })
      // .catch(er => console.log(er));
      if (!this.$cookies.isKey("user_profile_token")) {
        const lineProfile = await this.$liff.getProfile();
        this.$cookies.set("user_profile_token", lineProfile, 60 * 60 * 24 * 30);
      }
      if (!this.$cookies.isKey("token")) {
        await this.getToken();
      }
      await this.getProfile();
      this.isLoading = true;
    } catch (error) {
      console.log("");
      // alert(error);
    }
  },
  mounted() {},
  computed: {
    showFooter() {
      if (
        this.theme.line_layout == "Compact" &&
        (this.$route.name == "profile" ||
          this.$route.name == "manage profile" ||
          this.$route.name == "reward" ||
          this.$route.name == "privilege")
      ) {
        let bodyStyles = document.body.style;

        bodyStyles.setProperty("--padding-footer", "130px");
        return true;
      } else {
        let bodyStyles = document.body.style;

        bodyStyles.setProperty("--padding-footer", "80px");
      }

      return false;
    },
    isShowing() {
      if (
        this.$route.name == "validatetelephone" ||
        this.$route.name == "confirmotp" ||
        this.$route.name == "confirmotpedit" ||
        this.$route.name == "editprofile-telephone" ||
        this.$route.name == "editprofile" ||
        this.$route.name == "manage profile"
      )
        return true;
      return false;
    },
    isShowHeader() {
      document.body.style.setProperty("--margin-navbar", "80px");
      return false;
    },
    readyToUse() {
      return this.isLoading && this.isReady;
    },
    isReady() {
      return this.$store.state.templateReady;
    }
  },
  methods: {
    invertColor(hex, bw) {
      if (!hex) return;
      if (hex.indexOf("#") === 0) {
        hex = hex.slice(1);
      }

      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      if (hex.length !== 6) {
        throw new Error("Invalid HEX color.");
      }
      var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
      if (bw) {
        return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? "#000000" : "#FFFFFF";
      }
      // invert color components
      r = (255 - r).toString(16);
      g = (255 - g).toString(16);
      b = (255 - b).toString(16);
      // pad each with zeros and return
      return "#" + this.padZero(r) + this.padZero(g) + this.padZero(b);
    },
    async getTheme() {
      var data = this.$store.state.theme;

      this.theme = this.$store.state.theme;

      function hexToRgbA(hex, opacity = 0.7) {
        var c;
        if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
          c = hex.substring(1).split("");
          if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
          }
          c = "0x" + c.join("");
          return (
            "rgba(" +
            [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
            `,${opacity})`
          );
        }
      }
      this.background = data.themeBackgroundPage == 1 ? true : false;
      let secondaryColorLight = hexToRgbA(data.themeSecondaryColorLine, 0.2);
      let primaryColorLow = hexToRgbA(data.themePrimaryColor, 0.1);
      let LightColor = hexToRgbA(data.themeLightPrimaryColor, 0.1);
      let bodyStyles = document.body.style;
      bodyStyles.setProperty("--primary-color", data.themePrimaryColor);
      bodyStyles.setProperty("--primary-color-low", primaryColorLow);
      bodyStyles.setProperty("--secondary-color", data.themeSecondaryColorLine);
      bodyStyles.setProperty("--secondary-color-light", secondaryColorLight);
      bodyStyles.setProperty("--light-color", data.themeLightPrimaryColor);
      bodyStyles.setProperty("--light-color-low", LightColor);
      bodyStyles.setProperty("--disable-color", data.themeDisableColor);
      bodyStyles.setProperty(
        "--font-color",
        this.invertColor(data.themeSecondaryColorLine, true)
      );
      bodyStyles.setProperty(
        "--font-primary-color",
        this.invertColor(data.themePrimaryColor, true)
      );
      bodyStyles.setProperty("--theme-background-color", data.themeSolidColor);
      bodyStyles.setProperty("--text-color", data.font_color);
      bodyStyles.setProperty("--bar-color", data.bar_color);
      bodyStyles.setProperty("--tab-text-color", data.font_color);
      bodyStyles.setProperty("--reward-icon-color", data.themePrimaryColor);
      bodyStyles.setProperty(
        "--theme-navigation-bar-color",
        data.themeNavigationBarColor
      );
      bodyStyles.setProperty(
        "--theme-background-image",
        `url(${this.$store.state.domain}${data.themeBackGround})`
      );
      // this.genarateFont(data);
      this.isLoadingTheme = true;
    },

    async getToken() {
      await new Promise((resolve, reject) => {
        setTimeout(async () => {
          const res = await this.$store.dispatch("login");
          resolve();
        }, 0);
      });
      return;
    },
    async getProfile() {
      if (this.$cookies.isKey("token"))
        await new Promise((resolve, reject) => {
          setTimeout(async () => {
            const res = await this.$store.dispatch("getUserProfile");
            resolve();
          }, 0);
        });
      return;
    },
    async updateView() {
      await this.$nextTick();
      this.componentKey += 1;
    }
  }
};
</script>
<style>
:root {
  --margin-navbar: 80px;
}
.content {
  position: fixed;
  background-color: #fff;
  width: 100%;
}
/* .background-b {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background: linear-gradient(
    0deg,
    var(--secondary-color) 0%,
    var(--primary-color) 100%
  );
} */
.background-color {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: -1;
  background-color: var(--theme-background-color);
}
.background-image {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  z-index: -1;
  /* overflow: hidden; */
  background-image: var(--theme-background-image);
}
.background-telephone-color {
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  /* position: fixed; */
  width: 100%;
  top: 0;
  /* min-height: calc(100dvh - 80px); */
  z-index: -1;
  background-color: white;
  overflow: hidden;
}
.nav-footer {
  background: white;
  color: black;
  font-size: 28px;
  width: 100%;
  display: inline-flex;
  position: fixed;
  bottom: 0;
  height: 60px;
  z-index: 1;
  justify-content: space-around;
  align-items: center;
  box-shadow: -1px 9px 16px 0px rgb(138, 138, 138);
}
.badge-redeem {
  position: absolute;
  top: 4px;
  font-size: 10px !important;
  right: -8px;
}
.nav-footer-items {
  color: gainsboro;
}
.nav-footer-items.active {
  color: black;
}
</style>
