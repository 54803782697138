import store from "../store";
export default async function auth({ next, from, to, redirect }) {
  try {
    if (!store.state.templateReady || !store.state.shortProfile.user_guid) {
      const res = await store.dispatch("getUserBind");

      if (res == 1) {
        return next();
      } else {
        return next({
          path: "/validatetelephone",
          query: {
            redirect: to.fullPath
          }
        });
      }
    }

    return next();
  } catch (error) {
    console.error(error);
    console.log(error);
  }
}
