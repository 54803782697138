import store from "../store";

export default async function guestAuth({ next, to }) {
  if (!store.authenticate) {
    const res = await store.dispatch("checkHasUser");

    if (res.result == 0) return next();
    else if (!res) return next();
  }

  return next("/profile");
}
