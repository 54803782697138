const getAccessBrand = () => {
  let baseURL = "";
  let location = window.location.hostname;
  let brand = location.split("-")[0];
  var subdomain = location.split(".");
  subdomain.shift();
  subdomain = subdomain.join(".");

  if (brand == "hourglass") subdomain = "dosetech.co";
  if (
    brand == "linecrm" ||
    brand == "d" ||
    process.env.NODE_ENV == "development"
  ) {
    baseURL = "https://api-hourglass-mobile-dev.dosetech.co";
  } else if (brand == "dcrm")
    baseURL = "https://api-dcrm-demo-mobile.dosetech.co";
  else {
    if (brand == "clarins") {
      baseURL = "https://clubbeaute-mobile-api.dosetech.co";
    } else {
      baseURL = `${window.location.protocol}//api-${brand}-mobile-${process.env.VUE_APP_MODE}.${subdomain}`;
    }
  }
  return baseURL;
};
export { getAccessBrand };
